var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('ValidationObserver',{ref:"form",staticClass:"displayTemplate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-divider',{staticClass:"my-7"})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"8","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Nombre de la empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Nombre de la empresa","error-messages":errors},model:{value:(_vm.datosEmpresa.nombre),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "nombre", $$v)},expression:"datosEmpresa.nombre"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Clave","error-messages":errors,"required":"","id":"txtClave"},model:{value:(_vm.datosEmpresa.clave),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "clave", $$v)},expression:"datosEmpresa.clave"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Razón social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Razón social","required":"","error-messages":errors},model:{value:(_vm.datosEmpresa.razon_social),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "razon_social", $$v)},expression:"datosEmpresa.razon_social"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"RFC","rules":"required|rfc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"RFC","required":"","id":"RFC","maxlength":"18","error-messages":errors},model:{value:(_vm.datosEmpresa.rfc),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "rfc", $$v)},expression:"datosEmpresa.rfc"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Dirección","required":"","error-messages":errors},model:{value:(_vm.datosEmpresa.direccion),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "direccion", $$v)},expression:"datosEmpresa.direccion"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Código postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"outlined":"","label":"Código postal","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.busca_codigos()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.busca_codigos()}},model:{value:(_vm.busca_cp),callback:function ($$v) {_vm.busca_cp=$$v},expression:"busca_cp"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"3","md":"3"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Estado","disabled":"","id":"txtEstado"},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"3","md":"3"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Municipio","disabled":"","id":"txtMunicipio"},model:{value:(_vm.municipio),callback:function ($$v) {_vm.municipio=$$v},expression:"municipio"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Colonia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Colonia","items":_vm.colonias,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.datosEmpresa.colonia_id),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "colonia_id", $$v)},expression:"datosEmpresa.colonia_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Télefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"outlined":"","label":"Teléfono","error-messages":errors,"required":""},model:{value:(_vm.datosEmpresa.telefono_contacto),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "telefono_contacto", $$v)},expression:"datosEmpresa.telefono_contacto"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Contacto","error-messages":errors,"required":"","id":"txtContacto"},model:{value:(_vm.datosEmpresa.nombre_contacto),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "nombre_contacto", $$v)},expression:"datosEmpresa.nombre_contacto"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Correo Contacto","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Correo Contacto","error-messages":errors,"required":"","id":"txtCorreoContacto"},model:{value:(_vm.datosEmpresa.correo_contacto),callback:function ($$v) {_vm.$set(_vm.datosEmpresa, "correo_contacto", $$v)},expression:"datosEmpresa.correo_contacto"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }